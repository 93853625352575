<template>
  <div>
    <div class="_top_banner">
      <div class="__inner" ref="inner">
        <img src="../../assets/img/recommends/banner.jpg" class="bg" />
        <div class="_inner">
          <div class="_navs">
            <div
              :class="[
                '_nav_item',
                actived == key ? 'actived' : '',
                key == 0 ? '_first' : '',
              ]"
              v-for="(item, key) in navs"
              :key="key"
              @click="changeActive(key)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="_search_box">
            <input
              type="text"
              placeholder="搜索关键字"
              v-model="words"
              @keydown="checkSearch"
              maxlength="10"
            />
            <button
              title="清除"
              @mouseenter="btn_in = true"
              @mouseleave="btn_in = false"
              class="_clear"
              @click="doclear"
              v-show="words"
            >
              <img
                src="../../assets/img/recommends/close_hover.png"
                v-show="btn_in"
              />
              <img
                src="../../assets/img/recommends/close.png"
                v-show="!btn_in"
              />
            </button>
            <button
              title="搜索"
              @mouseenter="btn_in2 = true"
              @mouseleave="btn_in2 = false"
              @click="dosearch"
              class="_search"
            >
              <img
                src="../../assets/img/recommends/search_hover.png"
                v-show="btn_in2"
              />
              <img
                src="../../assets/img/recommends/search.png"
                v-show="!btn_in2"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="_articles" v-if="navs.length">
      <ArticleList
        v-for="(item, key) in navs"
        :key="key"
        :id="item.id"
        :isActived="actived == key"
      />
    </div>
  </div>
</template>
<script>
import { getClassification } from "../../api/wallpaper";
import ArticleList from "./article_list";
export default {
  name: "recommends",
  data() {
    return {
      actived: -1,
      navs: [],
      words: "",
      btn_in: false,
      btn_in2: false,
    };
  },
  components: {
    ArticleList,
  },
  mounted() {
    this.getNavs();
    let scrollbarEl = this.$scrollbar.wrap;
    scrollbarEl.addEventListener("scroll", (e) => {
      if (this.$refs.inner) {
        let x = scrollbarEl.scrollLeft;
        this.$refs.inner.style.transform = `translateX(-${x}px)`;
      }
    });
  },
  methods: {
    changeActive(idx) {
      if (this.actived !== idx) {
        this.actived = idx;
      }
    },
    doclear() {
      this.words = "";
    },
    checkSearch(e) {
      if (e.keyCode == 13) this.dosearch();
      else return;
    },
    dosearch() {
      const data = this.$router.resolve({
        path: "/searchresult",
        query: { keywords: this.words },
      });
      window.open(data.href, "_blank");
    },
    async getNavs() {
      const resp = await getClassification();
      if (resp.success) {
        this.navs = resp.data;
        if (this.$route.query.classificationId) {
          resp.data.map((item, key) => {
            if (item.id == this.$route.query.classificationId) {
              this.actived = key;
            }
          });
        } else this.actived = 0;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dbpa {
  display: block;
  position: absolute;
}
.middle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

._top_banner {
  width: 100%;
  min-width: 1300px;
  position: fixed;
  top: 70px;
  left: 0px;
  height: 164px;
  overflow: hidden;
  z-index: 9;
  .__inner {
    width: 100%;
    min-width: 1300px;
    position: absolute;
    height: 164px;
    left: 0;
    top: 0;
  }
  .bg {
    @extend .dbpa;
    @extend .middle;
  }
  ._inner {
    width: 1300px;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(22, 22, 22, 0.1);
    border-radius: 16px;
    position: relative;
    margin: 73px auto 0;
    ._navs {
      min-width: 600px;
      height: 64px;
      line-height: 64px;
      display: flex;
      float: left;
      ._nav_item {
        min-width: 80px;
        text-align: center;
        padding: 0 16px;
        cursor: pointer;
        &:hover {
          color: #3da7fe;
        }
        &.actived {
          font-size: 20px;
          font-weight: bold;
          color: #3da7fe;
          position: relative;
          &::after {
            display: block;
            content: " ";
            width: 30px;
            height: 4px;
            background: #3da7fe;
            border-radius: 2px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: 0;
          }
        }
        &._first {
          padding-left: 30px;
        }
      }
    }
    ._search_box {
      width: 228px;
      height: 34px;
      float: right;
      background: #e9f0f6;
      border: 2px solid #e9f0f6;
      border-radius: 8px;
      margin: 14px 22px;
      position: relative;
      overflow: hidden;
      &:hover {
        border: 2px solid rgba(61, 167, 254, 0.94);
      }
      input {
        display: block;
        float: left;
        width: 168px;
        height: 34px;
        background: #e9f0f6;
        border: none;
        text-indent: 16px;
      }
      button {
        @extend .dbpa;
        cursor: pointer;
        overflow: hidden;
        padding: 0;
        border: none;
        background: none;
      }
      ._clear {
        width: 14px;
        height: 14px;
        top: 10px;
        right: 46px;
      }
      ._search {
        width: 20px;
        height: 20px;
        top: 7px;
        right: 16px;
      }
    }
  }
}
._articles {
  width: 100%;
  height: auto;
  clear: both;
  margin-top: 164px;
}
</style>
